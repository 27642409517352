<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.year"
                    v-bind:label="$t('Year')"
                    :items="year_list"
                    :hide-details="true"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="selected.month"
                    v-bind:label="$t('Month')"
                    item-text="name"
                    item-value="id"
                    :items="monthList"
                    :hide-details="true"
                    filled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.brandId"
                    v-bind:label="`${$t('brands.brand')}`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brand"
                    :loading="isBrandSearching"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.posId"
                    v-bind:label="`POS`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_pos"
                    :loading="isPOSSearching"
                    :items="filtered_pos"
                    hide-no-data
                    hide-selected
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.salesFamily"
                    v-bind:label="
                      `${this.$t('brands.Sales')} ${this.$t('brands.family')}`
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.amount"
                    v-bind:label="$t('amount')"
                    type="number"
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.planAmount"
                    v-bind:label="`${this.$t('brands.Sales')} Plan €`"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.volume"
                    v-bind:label="$t('volume')"
                    type="number"
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.planVolume"
                    v-bind:label="`${this.$t('brands.Sales')} Plan Vol`"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ `${this.$t("brands.Sales")} Import` }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isSelectingBrandSales"
              @click="onUploadExcelBrandSalesClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonTextBrandSales }}
            </v-btn>
            <input
              ref="uploaderBrandSales"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChangedBrandSales"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="brandsales-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "Assortments",
  components: {},
  data: function() {
    return {
      tableData: [],
      totaltableData: 0,
      importExcelTemplatePathName: null,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      filterable_col_list: [
        "year",
        "month",
        "brand",
        "pos",
        "amount",
        "volume",
        "planAmount",
        "planVolume",
        "salesFamily",
      ],
      filterCriteria: {
        year: {},
        month: {},
        brand: {},
        pos: {},
        amount: {},
        volume: {},
        planAmount: {},
        planVolume: {},
        salesFamily: {},
      },
      filterlist_fetch_url: "brands/sales/getfilterlist",
      showEmpty: true,

      /********** Import and Export Excel **********/
      isSelectingBrandSales: false,
      selectedFileBrandSales: null,
      defaultbuttonTextBrandSales: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** form-validation options **********/
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      showGoDialog: false,
      search_pos: null,
      isPOSSearching: false,
      filtered_pos: [],
      year_list: [],
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
    };
  },
  watch: {
    async search_pos(val) {
      await this.search_pos_list(val);
    },
    async search_brand(val) {
      await this.search_brand_list(val);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    buttonTextBrandSales() {
      return this.isSelectingBrandSales
        ? "Uploading..."
        : this.defaultbuttonTextBrandSales;
    },
    col_year() {
      return {
        key: "year",
        title: this.$t("Year"),
        field: "year",
        align: "left",
        width: 100,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["from"]}
                  value={this.filterCriteria["year"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["to"]}
                  value={this.filterCriteria["year"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "year")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_month() {
      return {
        key: "month",
        title: this.$t("Month"),
        field: "month",
        align: "left",
        width: 100,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["from"]}
                  value={this.filterCriteria["month"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["to"]}
                  value={this.filterCriteria["month"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "month")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_brandId() {
      return {
        key: "brandId",
        title: "ID " + this.$t("brands.brand"),
        field: "brandId",
        align: "left",
        width: 100,
        sortBy: "",
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brand") + " " + this.$t("brands.name"),
        field: "brand",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand && row.brand.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_amount() {
      return {
        key: "amount",
        title: this.$t("amount"),
        field: "amount",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["amount"]["from"]}
                  value={this.filterCriteria["amount"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["amount"]["to"]}
                  value={this.filterCriteria["amount"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "amount")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_volume() {
      return {
        key: "volume",
        title: this.$t("volume"),
        field: "volume",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["volume"]["from"]}
                  value={this.filterCriteria["volume"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["volume"]["to"]}
                  value={this.filterCriteria["volume"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "volume")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_planAmount() {
      return {
        key: "planAmount",
        title: `${this.$t("brands.Sales")} Plan €`,
        field: "planAmount",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["planAmount"]["from"]}
                  value={this.filterCriteria["planAmount"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["planAmount"]["to"]}
                  value={this.filterCriteria["planAmount"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "planAmount")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_planVolume() {
      return {
        key: "planVolume",
        title: `${this.$t("brands.Sales")} Plan Vol`,
        field: "planVolume",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["planVolume"]["from"]}
                  value={this.filterCriteria["planVolume"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["planVolume"]["to"]}
                  value={this.filterCriteria["planVolume"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "planVolume")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_salesFamily() {
      return {
        key: "salesFamily",
        title: `${this.$t("brands.Sales")} ${this.$t("brands.family")}`,
        field: "salesFamily",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["salesFamily"]}
                  apiUrl={this.filterlist_fetch_url}
                  columnName={`salesFamily`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["salesFamily"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "salesFamily")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_edit() {
      return {
        key: "actions",
        title: this.$t("edititem"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        },
      };
    },
    columns() {
      return [
        this.col_year,
        this.col_month,
        this.col_posId,
        this.col_pos,
        this.col_brandId,
        this.col_brand,
        this.col_salesFamily,
        this.col_amount,
        this.col_planAmount,
        this.col_volume,
        this.col_planVolume,
        this.col_edit,
      ];
    },
  },
  methods: {
    async search_pos_list(val) {
      if (!val) val = "";
      let fetch_url = `brands/sales/get_search_list?target=pos&&filter_name=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_pos = resp.data;
    },
    async search_brand_list(val) {
      if (!val) val = "";
      let fetch_url = `brands/sales/get_search_list?target=brands&&filter_name=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brandsales-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#brandsales-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/sales";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "brands/sales/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "sales.xlsx");
      this.close();
    },
    onUploadExcelBrandSalesClick() {
      this.isSelectingBrandSales = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingBrandSales = false;
        },
        { once: false }
      );
      this.$refs.uploaderBrandSales.click();
    },
    async onFileChangedBrandSales(e) {
      this.selectedFileBrandSales = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingBrandSales = true;
        let data = new FormData();
        data.append("file", this.selectedFileBrandSales);
        this.$refs.uploaderBrandSales.value = "";
        try {
          const response = await ApiService.post(
            `brands/sales/uploadexcelBrandSales/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingBrandSales = false;
          await this.getDataFromApi();
        } catch (error) {
          console.log(error);
          this.isSelectingBrandSales = false;
        }
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        year: _.get(item, "year"),
        month: _.get(item, "month"),
        amount: _.get(item, "amount"),
        volume: _.get(item, "volume"),
        planAmount: _.get(item, "planAmount"),
        planVolume: _.get(item, "planVolume"),
        salesFamily: _.get(item, "salesFamily"),
        posId: _.get(item, "posId"),
        po: _.get(item, "po"),
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
      };
      if (model.po) {
        this.filtered_pos = [model.po];
      } else {
        await this.search_pos_list("");
      }
      if (model.brand) {
        this.filtered_brands = [model.brand];
      } else {
        await this.search_brand_list("");
      }
      console.log(model);
      return model;
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["po"]);
        let status = null;
        if (body.id) {
          status = await ApiService.put(`brands/sales/${body.id}`, body);
        } else {
          status = await ApiService.post(`brands/sales`, body);
        }
        if (status) {
          logInfo("Éxito !");
          this.getDataFromApi();
        } else {
          logError("Fallido !");
        }
        this.showEdit = false;
      }
    },
    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    let cur_date = new Date();
    let cur_year = cur_date.getFullYear();
    for (let i = cur_year + 1; i >= cur_year - 10; i--) {
      this.year_list.push(i);
    }
    this.init();
  },
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
